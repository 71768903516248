$(document).ready(function () {
  // detect navbar position
  var domain = "https://www.bestbarcastlerock.com/";
  var home_ = domain;
  var about_ = domain + "about";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  const pages = [
    { page: "home", file: home_ },
    { page: "about", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];
  $(
    ".home, .about, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];

      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });

      if (pageObj) {
        var file = pageObj.file;

        window.location.href = file;
        //console.log('Haz clic en ' + file);
      }
    });
  });
  //add nav activo
  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;

    $(".page").each(function () {
      var link = $(this).text().toLowerCase();

      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });

    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });
  //delete photo link to url on gallery
  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  //console.log('Ubicación: ' + currentPath);
  if (allowedPaths.includes(currentPath)) {
    $('[data-fancybox="gallery"], [data-fancybox="gallery_2"]').fancybox({
      hash: false,
    });
  }
  //add classes on load
  $("header section img").addClass("transition");
  $("body main article.info_btn span.address_link i").addClass("bounce-in");
  //add property visibility visible to body
  $("body").css("visibility", "visible");
  // function on scroll
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 30) {
      $("header").addClass("sticky_header");
    } else {
      $("header").removeClass("sticky_header");
    }
  });
  // add class on scroll to button up
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 200) {
      $(".up").addClass("show_btn");
    } else {
      $(".up").removeClass("show_btn");
    }
  });
  // click off
  $(document).on("click", function (event) {
    var button = $(".order");
    var dropdown = $(".orders");
    if (!dropdown.is(event.target) && !button.is(event.target)) {
      dropdown.css("display", "none");
      dropdown.removeClass("despliega");
      $(button).removeClass("rotate_reverse");
    }
  });
  // dropdown menu
  $(".order").on("click", function () {
    var dropdown = $(".orders");
    if (dropdown.css("display") == "none" || dropdown.css("display") == "") {
      dropdown.css("display", "flex");
      dropdown.addClass("despliega");
      $(this).addClass("rotate_reverse");
    } else {
      dropdown.css("display", "none");
      dropdown.removeClass("despliega");
      $(this).removeClass("rotate_reverse");
    }
  });
  // button up
  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
    //console.log("Vamos arriba");
  });
  // Establecer el año
  var yir = new Date().getFullYear();
  var yy = new Date();
  $(".year").text(yir);
  console.log(yy);
  // navbar on menu
  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");

    menuDivs.hide();

    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");

    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();

        menuDivs.eq(index).show();

        tabs.removeClass("colorea");
        $(this).addClass("colorea");

        //console.log('Pestaña clickeada: ' + $(this).text());
        //console.log('Div mostrado: ' + menuDivs.eq(index).attr('class'));
      });
    });
  }
  menuTabs();
  //add link to logo
  $(".logo").click(function () {
    window.location.href = domain;
  });
  //get li from navigation navbar on mobile
  $("li.silence button").on("click", function () {
    console.log("Vamos bien");
    $(this).toggleClass("clickeado");
    //$('header nav ul').toggleClass('expandete');
    $(".nav_mobile_mob").toggleClass("expandete");
  });
  //delete html to url
  function removeHtmlExtension(url) {
    if (url.endsWith(".html")) {
      return url.slice(0, -5);
    }
    return url;
  }
  //scroll-to
  $(document).ready(function () {
    // Capturar el evento clic en los enlaces internos
    $("span[data-scroll-to]").click(function (event) {
      event.preventDefault();

      var target = $(this).data("scroll-to");
      var offset = $(target).offset().top;

      $("html, body").animate({ scrollTop: offset }, 500);
    });
  });
  // run swiper on home and about
  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  //console.log('Ubicación: ' + currentPath);
  if (allowedPaths.includes(currentPath)) {
    $(document).ready(function () {
      var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

      var cycle = function (index) {
        var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

        nextIndex = index !== undefined ? index : nextIndex;

        $currentSlide = $($slides.get(currentIndex));
        $currentSelector = $($selectors.get(currentIndex));

        $nextSlide = $($slides.get(nextIndex));
        $nextSelector = $($selectors.get(nextIndex));

        $currentSlide.removeClass("active").css("z-index", "0");

        $nextSlide.addClass("active").css("z-index", "1");

        $currentSelector.removeClass("current");
        $nextSelector.addClass("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < $slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
      };

      $(function () {
        currentIndex = 0;
        nextIndex = 1;

        $slides = $(".slide");
        $selectors = $(".selector");
        $btns = $(".btn");

        $slides.first().addClass("active");
        $selectors.first().addClass("current");

        interval = window.setInterval(cycle, 4000);

        $selectors.on("click", function (e) {
          var target = $selectors.index(e.target);
          if (target !== currentIndex) {
            window.clearInterval(interval);
            cycle(target);
            interval = window.setInterval(cycle, 4000);
          }
        });

        $btns.on("click", function (e) {
          window.clearInterval(interval);
          if ($(e.target).hasClass("prev")) {
            var target =
              currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
            cycle(target);
          } else if ($(e.target).hasClass("next")) {
            cycle();
          }
          interval = window.setInterval(cycle, 4000);
        });
      });

      var menu = [];
      $(".swiper-slide").each(function (index) {
        menu.push($(this).find(".slide-inner").attr("data-text"));
      });

      var interleaveOffset = 0.5;
      var swiperOptions = {
        loop: true,
        speed: 1000,
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 0,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      var swiper = new Swiper(".swiper-container", swiperOptions);

      var sliderBgSetting = $(".slide-bg-image");
      sliderBgSetting.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css(
            "background-image",
            "url(" + $(this).data("background") + ")"
          );
        }
      });
    });
  }
  // run swiper on home and about
  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  //console.log('Ubicación: ' + currentPath);
  if (allowedPaths.includes(currentPath)) {
    //add property visibility visible to body
    $("body").addClass("no-scroll");
    $(".modal").addClass("show_modal");
    //close modal
    $(".btn_modal").on("click", function () {
      //$('.modal').removeClass('show_modal');
      $(".modal").addClass("animationless");
      $(".wrap").addClass("animationless2");
      $("body").removeClass("no-scroll");
    });
  }
  //theme color
  var themeColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--primary")
    .trim();
  // Actualiza el atributo "content" de la etiqueta meta
  $('meta[name="theme-color"]').attr("content", themeColor);
  // get vh
  const alturaViewport = $(window).height();
  const vhEquivalentes = (600 / alturaViewport) * 100;
  console.log("the view height is " + vhEquivalentes);

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });
  //--------------------------------------- Data//---------------------------------------
  //Location Info
  LOCATION_NAME = "The Library Co.";
  ADDRESS = "3911 Ambrosia St Ste 101, Castle Rock";
  ADDRESS_URL = "https://maps.app.goo.gl/RzCuCE7eYJHTH7hw9";
  PHONE_NUMBER = "7203895986";
  PHONE_TEXT = "(720) 389-5986";
  //Hours
  HOURS_ST = "11:00 a.m. - 9:00 p.m.";
  HOURS_ND = "11:00 a.m. - 9:30 p.m.";
  HOURS_RD = "11:00 a.m. - 10:00 p.m.";
  HOURS_TH = "9:30 a.m. - 10:00 p.m.";
  //
  HB1 = "11:00 a.m. - 11:00 p.m.";
  HB2 = "11:00 a.m. - 12:00 a.m.";
  HB3 = "9:00 a.m. - 12:00 a.m.";
  //
  DB1 = "Sun - Thu";
  DB2 = "Fri";
  DB3 = "Sat";
  //Days
  D1 = "sun - wed";
  D2 = "thu";
  D3 = "fri";
  D4 = "sat";
  //Social media
  FACEBOOK =
    "https://www.facebook.com/profile.php?id=100053452762317&ref=embed_page";
  YELP =
    "https://www.yelp.com/biz/the-library-co-bar-and-kitchen-castle-rock?osq=The+Library+Co.+Bar+%26+Kitchen";
  //3Rd Party Vendors
  PICK_UP = "https://order.toasttab.com/online/the-library-co-bar-kitchen#!/";
  //Google Reviews
  GOOGLE_DESK =
    "https://www.google.com/search?channel=fs&client=ubuntu-sn&q=the+library+co#lrd=0x876c99806df4d8b1:0x6041b6976719374a,3,,,,";
  GOOGLE_MOB =
    "https://www.google.com/search?q=the%20library%20co&oq=the%20library%20co&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDQ1NTZqMGo5qAIAsAIA&client=ms-android-attmexico-mx-revc&sourceid=chrome-mobile&ie=UTF-8&asid=canlbsc#wptab=si:ALGXSlb9ylv6FQuI8zCROKWgJkMHV_7qC6_qVQjaGVH5mNU2SfoBIch9Av48LmNl-WdUFISjGk5AE_g2z5mUyQsh9L147RxBzMzUJqL4gHaqjrMCMG-LSMe2mJ4sPaPQo_PX6GXrSdRNzvmamcWVitzGvuCAoCW0aWboeh3aDIGf9-Z5cl-dKzI%3D";
  //About Us
  ABOUT_US =
    "At The Library Co. Bar & Kitchen, we serve the Castle Rock community with delicious brunch, lunch, dinner, and drinks. Enjoy dishes like flat iron steak, mahi tacos, and chicken parmigiana, paired with craft beers, wines, or signature cocktails. <br><br> Located at 3911 Ambrosia St Ste 101, we offer a welcoming atmosphere perfect for any occasion. Whether you're stopping by for a casual meal or a night out, our team is dedicated to providing great food and friendly service.";
  ABOUT_US_SHORTY =
    "At The Library Co. Bar & Kitchen, we serve the Castle Rock community with delicious brunch, lunch, dinner, and drinks. Enjoy dishes like flat iron steak, mahi tacos, and chicken parmigiana, paired with craft beers, wines, or signature cocktails. <br><br> Located at 3911 Ambrosia St Ste 101, we offer a welcoming atmosphere perfect for any...";
  //Iframe
  IFRAME =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3082.9550493124457!2d-104.886556!3d39.402522999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c99806df4d8b1%3A0x6041b6976719374a!2sThe%20Library%20Co.%20Bar%20%26%20Kitchen!5e0!3m2!1sen!2sus!4v1697053489199!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  //About Us
  $(".text_about_home").each(function () {
    $(this).html(ABOUT_US_SHORTY);
  });
  $(".text_about").each(function () {
    $(this).html(ABOUT_US);
  });
  //Iframe
  $(".iframe").each(function () {
    $(this).html(IFRAME);
  });
  //Location Name
  $(".location_name").each(function () {
    $(this).text(LOCATION_NAME);
  });
  //Location Info
  $(".address_link").each(function () {
    $(this).click(function () {
      window.open(ADDRESS_URL, "_blank");
    });
  });
  $(".address_text").each(function () {
    $(this).text(ADDRESS);
  });
  //Phone
  $(".phone").each(function () {
    $(this).click(function () {
      window.location.href = "tel:" + PHONE_NUMBER;
    });
  });
  $(".phone_number").each(function () {
    $(this).text(PHONE_TEXT);
  });
  //Google Reviews
  $(".google_desk").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_DESK, "_blank");
    });
  });
  $(".google_mob").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_MOB, "_blank");
    });
  });
  //Days
  $(".d1").each(function () {
    $(this).text(D1);
  });
  $(".d2").each(function () {
    $(this).text(D2);
  });
  $(".d3").each(function () {
    $(this).text(D3);
  });
  $(".d4").each(function () {
    $(this).text(D4);
  });
  //
  $(".db1").each(function () {
    $(this).text(DB1);
  });
  $(".db2").each(function () {
    $(this).text(DB2);
  });
  $(".db3").each(function () {
    $(this).text(DB3);
  });
  //Hours
  $(".h1").each(function () {
    $(this).text(HOURS_ST);
  });
  $(".h2").each(function () {
    $(this).text(HOURS_ND);
  });
  $(".h3").each(function () {
    $(this).text(HOURS_RD);
  });
  $(".h4").each(function () {
    $(this).text(HB1);
  });
  //
  $(".hb1").each(function () {
    $(this).text(HB1);
  });
  $(".hb2").each(function () {
    $(this).text(HB2);
  });
  $(".hb3").each(function () {
    $(this).text(HB3);
  });
  //Social Media
  $(".fb").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".yp").each(function () {
    $(this).click(function () {
      window.open(YELP, "_blank");
    });
  });
  //3Rd Party Vendors
  $(".pickup").each(function () {
    $(this).click(function () {
      window.open(PICK_UP, "_blank");
    });
  });
});
